//import logoDesktop from '../assets/logo-analytics-desktop.svg';
import { useTranslation } from "react-i18next";
import { useCallback, useState } from "react";
import useAppAuthentication from "../../hooks/use-app-authentication";
import logoDesktop from '../../assets/logo-advertising-desktop.svg';
import logoComitium from '../../assets/logo-comitium.svg';
import {MetadataLoginToken} from "../../domain/Token";

const defaultMetadataLoginToken : MetadataLoginToken = {
    cs_user: {
        id: 999999,
        name: 'system_admin',
        surnames: '',
    },
    cs_roles: {
        analytics: '',
        advertising: 'basic',
        ai: '',
    }
}

const Login = () => {
    const { t } = useTranslation();
    const [publicKey, setPublicKey] = useState<string>("");
    const [secretKey, setSecretKey] = useState<string>("");
    const { login, error, isAuthenticating } = useAppAuthentication();

    const canSubmit = useCallback((): boolean => {
        return !isAuthenticating && publicKey.length > 0 && secretKey.length > 0
    }, [isAuthenticating, publicKey, secretKey])

    const submit = useCallback(() => {
        if (canSubmit()) void login(
            {
                publicKey,
                secretKey,
                metadata: defaultMetadataLoginToken
            }).then()
    }, [publicKey, secretKey, login, canSubmit])

    return <div className='oc-flex oc-flex--center is-static-page'>
        <div className="cc-login">
            <div className="cc-login__wrapper">
                <img className="mc-logo__image--static" src={logoDesktop} alt={"Logo Comitium Advertising"} />
                <div className="cc-login__form">
                    <label>{t('public key')}</label>
                    <input
                        type={"text"}
                        value={publicKey}
                        onChange={(e) => setPublicKey(e.target.value)}
                        className={"cc-form__input"}
                    />
                </div>
                <div className="cc-login__form">
                    <label>{t('secret key')}</label>
                    <input
                        type={"password"}
                        value={secretKey}
                        onChange={(e) => setSecretKey(e.target.value)}
                        className={"cc-form__input"}
                    />
                </div>
                { error &&
                        <div className="cc-login__error">{t('error login')}</div>
                }
                <div className={"mc-button mc-button--primary" + (!canSubmit() ? " is-disabled" : "")} onClick={submit}>{t('login')}</div>
            </div>
            <a href="https://comitium.io" target="_blank" rel="noreferrer"><img className="mc-logo__image--static mc-logo--comitium-login" src={logoComitium} alt={"Logo Comitium"} /></a>
        </div>
    </div>
}

export default Login