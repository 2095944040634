import axios, {AxiosResponse} from "axios";
import {COMITIUM_URL_KEY, TOKEN_KEY} from "../domain/Constants";
import {ExternalAppsAccess, MetadataLoginToken} from "../domain/Token";

class ApiClient {
    private readonly url: string
    private readonly token: string
    private abortController: AbortController|undefined

    constructor() {
        this.url = process.env.NODE_ENV === "development" ? "http://localhost:8100" : "https://api.comitiumadvertising.com"
        this.token = localStorage.getItem(TOKEN_KEY) ?? ""
    }

    public async auth(token: string) {
        const response = await axios({
            method: "get",
            url: this.url + `/auth/${token}`,
        })

        localStorage.setItem(TOKEN_KEY, response.data.token)
    }

    public async login(token: string) {
        const response = await axios({
            method: "post",
            url: this.url + "/auth-login",
            headers: {
                Authorization: token
            }
        })

        localStorage.setItem(TOKEN_KEY, response.data.token)
    }

    public logout()
    {
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(COMITIUM_URL_KEY)
    }

    public abort()
    {
        this.abortController?.abort()
    }

    public request(method: methodTypes, endpoint: string, parameters: any = null): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method,
            url: this.url + endpoint,
            headers: {
                "Content-Type": (method === "get" ? "application/json" : "application/x-www-form-urlencoded"),
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        if (method === "get") {
            Object.assign(params, {params: parameters})
        } else {
            Object.assign(params, {data: parameters})
        }

        return axios(params)
    }

    public async getToken(publicKey: string, secretKey: string, metadata: MetadataLoginToken): Promise<string | null> {
        try {
            const response = await axios({
                method: "post",
                url: this.url + "/auth",
                headers: {
                    "Content-Type": "application/json",
                },
                data: {
                    publicKey,
                    secretKey,
                    metadata,
                },
            });

            return response.data.token ?? null;
        } catch (error) {
            return null;
        }
    }

    public async getExternalAppsAccess(token: string): Promise<ExternalAppsAccess|null> {
        try {
            const response = await axios({
                method: "post",
                url: this.url + "/external-apps-access",
                headers: {
                    Authorization: token
                }
            })

            return response.data
        }catch(error) {
            return null
        }
    }

    public getMetricsStats(dimension: string, parameters: any = null): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/stats/metrics/' + dimension,
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {params: parameters})

        return axios(params)
    }

    public getInvoices(parameters: any = null): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/invoices',
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {params: parameters})

        return axios(params)
    }

    public getInvoice(invoiceId: string): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/invoice/' + invoiceId,
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }


        return axios(params)
    }

    public getInvoiceDetailsPendingPayment(): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/invoice-detail/pending',
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }


        return axios(params)
    }

    public createInvoiceDetail(parameters: any = null): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "post",
            url: this.url + '/api/invoice-detail/create',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {data: parameters})

        return axios(params)
    }

    public updateInvoiceDetail(invoiceDetailId: number, parameters: any = null): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "put",
            url: this.url + '/api/invoice-detail/' + invoiceDetailId,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {data: parameters})

        return axios(params)
    }

    public deleteInvoiceDetail(invoiceDetailId: number): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "delete",
            url: this.url + '/api/invoice-detail/' + invoiceDetailId,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        return axios(params)
    }

    public getAllSubsitesBackOffice(includeNAOption = false): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/backoffice/projects',
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {params: {na_option: (includeNAOption ? 1: 0)}})

        return axios(params)
    }

    public getAllProjects(): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/backoffice/projects/',
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        return axios(params)
    }

    public getAllInvoiceGroupsBackOffice(): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/invoice-groups/',
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        return axios(params)
    }

    public getAllPartnersBackOffice(includeNAOption = false): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/backoffice/partners/',
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {params: {na_option: (includeNAOption ? 1: 0)}})

        return axios(params)
    }

    public getAllDevicesBackOffice(): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/backoffice/devices/',
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        return axios(params)
    }

    public getAllEnvironmentsBackOffice(): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/backoffice/environments/',
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        return axios(params)
    }

    public getReachableInvoiceStates(stateId: number): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/invoice/states/' + stateId,
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
            },
            signal: this.abortController.signal
        }

        return axios(params)
    }

    public changeInvoiceState(invoiceId: number, stateId: number, sendEmail: boolean): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "post",
            url: this.url + '/api/invoice/' + invoiceId + '/state/change',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: this.token,
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {data: {state_id: stateId, send_email: sendEmail ? 1 : 0}})

        return axios(params)
    }

    public downloadInvoicePdf(invoiceId: number): Promise<Blob> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/invoice/' + invoiceId + '/download',
            headers: {
                Accept: "application/pdf",
                Authorization: this.token,
            },
            signal: this.abortController.signal,
        };

        Object.assign(params, {responseType: "blob" as ResponseType})

        return axios(params)
            .then((response: AxiosResponse<Blob>) => {
                return response.data;
            })
            .catch((error) => {
                throw error;
            });
    }

    public createRevenueShare(parameters: any = null): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "post",
            url: this.url + '/api/revenue-share',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {data: parameters})

        return axios(params)
    }

    public updateRevenueShare(revenueShareId: number, parameters: any = null): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "put",
            url: this.url + '/api/revenue-share/' + revenueShareId,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {data: parameters})

        return axios(params)
    }

    public deleteRevenueShare(invoiceDetailId: number): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "delete",
            url: this.url + '/api/revenue-share/' + invoiceDetailId,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        return axios(params)
    }

    public getRevenueShares(parameters: any = null): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/revenue-shares',
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {params: parameters})

        return axios(params)
    }

    public getInvoiceGroup(invoiceGroupId: string): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/invoice-group/' + invoiceGroupId,
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }


        return axios(params)
    }

    public createInvoiceGroup(parameters: any = null): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "post",
            url: this.url + '/api/invoice-group',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {data: parameters})

        return axios(params)
    }

    public updateInvoiceGroup(invoiceGroupId: number, parameters: any = null): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "put",
            url: this.url + '/api/invoice-group/' + invoiceGroupId,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {data: parameters})

        return axios(params)
    }

    public deleteInvoiceGroup(invoiceGroupId: number): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "delete",
            url: this.url + '/api/invoice-group/' + invoiceGroupId,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        return axios(params)
    }

    public getProject(projectId: string): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/projects/' + projectId,
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }


        return axios(params)
    }

    public createProject(parameters: any = null): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "post",
            url: this.url + '/api/project',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {data: parameters})

        return axios(params)
    }

    public updateProject(projectId: number, parameters: any = null): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "put",
            url: this.url + '/api/project/' + projectId,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {data: parameters})

        return axios(params)
    }

    public deleteProject(projectId: number): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "delete",
            url: this.url + '/api/project/' + projectId,
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        return axios(params)
    }

    public getHistoricalData(parameters: any = null): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/stats/metrics/historical',
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {params: parameters})

        return axios(params)
    }

    public getOrphanMetrics(parameters: any = null): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/metrics/orphan',
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {params: parameters})

        return axios(params)
    }

    public getComparedStats(parameters: any = null): Promise<AxiosResponse> {
        this.abortController = new AbortController();

        const params = {
            method: "get",
            url: this.url + '/api/metrics/compare',
            headers: {
                "Content-Type": "application/json",
                Authorization: this.token,
                timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone
            },
            signal: this.abortController.signal
        }

        Object.assign(params, {params: parameters})

        return axios(params)
    }
}

type methodTypes = "get"|"post"|"put"|"delete";

export default ApiClient